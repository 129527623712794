const jquery = require("jquery");
window.$ = window.jQuery = jquery;

$(document)
  .ready(function () {

    $('body').on('keypress','[role="button"]', function(e){
      if(e.which === 32){
        e.preventDefault();
        $(this).trigger('click');
      }
    });

    $('.ui.sidebar').sidebar('attach events', '.toc.item');

    $('.sideitem').on("click", function () {
      $('.ui.sidebar')
        .sidebar('toggle');
    });

    $('.ui.accordion').accordion();

    $('.maps').click(function () {
      $('.maps iframe').css("pointer-events", "auto");
    });
    $('.maps').mouseleave(function () {
      $('.maps iframe').css("pointer-events", "none");
    });

    AA_CONFIG = {
      animationLength: 500,
      easingFunction: 'swing',
      scrollOffset: 55
    };

    if (window.location.pathname === "/claire-donront.html") {
      $(document).find('a[href="claire-donront.html"]').addClass('active');
    }
    if (window.location.pathname === "/magali-renoult.php") {
      $(document).find('a[href="magali-renoult.php"]').addClass('active');
    }
    if (window.location.pathname === "/laure-moreau.php") {
      $(document).find('a[href="laure-moreau.php"]').addClass('active');
    }

  });

